.Verification-page {
  padding: 40px 0;
  width: 100%;
  height: 100%;
  min-height: 730px;
  background-color: #1c0057;
  .wrap {
    .Verification-box {
      min-height: 730px;
      width: 100%;

      border-radius: 18px;
      background-color: #fff;
      @include media("<=tablet") {
        padding: 20px 30px;
        min-width: 0;
      }
      .Verification-header {
        padding: 30px 60px;
        display: flex;
        @include media("<=tablet") {
          display: none;
          padding: 0px 60px;
        }
        .heading {
          color: #1c0057;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          @include media("<=tablet") {
            font-size: 20px;
          }
        }
      }
      .verify {
        .veri-heading {
          text-align: center;
          color: #1c0057;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          @include media("<=tablet") {
            font-size: 20px;
          }
        }
        .veri-desc {
          color: #320b85;
          text-align: center;
          font-size: 25px;
          font-style: normal;
          font-weight: 500;
          margin: 0 auto;
          line-height: 35px;
          margin-top: 50px;
          margin-bottom: 20px;
          width: 70%;
          @include media("<=tablet") {
            font-size: 14px;
            line-height: 20px;
            margin-top: 30px;
            margin-bottom: 10px;
            width: 100%;
          }
        }
        .veri-img {
          .img-box {
            cursor: pointer;
            height: 200px;
            width: 200px;
            border-radius: 8px;
            background: #f6f6f6;
            @include media("<=tablet") {
              height: 150px;
              width: 150px;
              border-radius: 6px;
            }
            .img {
              width: 150px;
              height: 150px;
              @include media("<=tablet") {
                height: 100px;
                width: 100px;
                border-radius: 6px;
              }
            }
            .img-desc {
              color: #320b85;
              text-align: center;
              font-size: 20px;
              font-style: normal;
              font-weight: 500;
              @include media("<=tablet") {
                font-size: 10px;
              }
            }
            .desc-1 {
              color: #320b85;
              text-align: center;
              font-size: 16px;
              font-style: normal;
              font-weight: 400;
              @include media("<=tablet") {
                font-size: 10px;
              }
            }
          }
        }
        .img-box {
          width: 110px;
          height: 110px;
          cursor: pointer;
          overflow: hidden;
          border-radius: 14px;
          border: 1px dashed #4c535f;
          background: #edf2f6;
          .i-img {
            width: 80px;
            height: 80px;
            @include media("<=tablet") {
              height: 100px;
              width: 100px;
              border-radius: 6px;
            }
          }
          .img {
            height: 100%;
            width: 100%;
            border-radius: 8px;
            object-fit: cover;
          }
          .u-lbl {
            padding: 8px;
            color: #4c535f;
            text-align: center;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          svg {
            width: 100px;
            height: 40px;
            path {
              fill: #d6d6d6;
            }
          }
          .select-file {
            display: none;
          }
        }
        .btns {
          .can {
            color: #1c0057;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            background: #e069e4;
            border-radius: 100px;
            padding: 16px 25px;
            @include anim;
            &:hover {
              background: #1c0057;
              color: #fff;
            }
            @include media("<=tablet") {
              font-size: 10px;
              padding: 10px 18px;
            }
          }
          .sub {
            border-radius: 100px;
            background: #1c0057;
            padding: 16px 25px;
            color: #fff;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
            @include anim;
            &:hover {
              background: #e069e4;
              color: #1c0057;
            }
            @include media("<=tablet") {
              font-size: 10px;
              padding: 10px 18px;
            }
          }
        }
      }
    }
  }
}
