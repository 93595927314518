.login-page {
  min-height: 730px;
  height: 100vh;

  .shade {
    bottom: 0;
    right: 0;
    height: 400px;
    width: 400px;
    border-radius: 398px;
    background: rgba(224, 105, 228, 0.2);
    filter: blur(70px);
    @include media("<=tablet") {
      bottom: 0;
      left: 0;
      width: 200px;
      height: 200px;
    }
  }
  .login_block {
    z-index: 100;
    inset: 0;
    position: absolute;
    @include media("<=tablet") {
      flex-direction: column;
    }
    .login-left {
      .bg-left {
        width: 100%;
        height: 100%;
        background-image: url("../../../public/images/bg-signup.png");
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
      }
      @include media("<=tablet") {
        display: none;
      }
    }
    .login-right {
      padding: 60px 5%;
      @include media("<=tablet") {
        inset: 0;
        position: absolute;
      }
      .page-header {
        .p-logo {
          display: flex;
          height: 120px;
        }
        .title {
          color: #fff;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          line-height: 35px;
        }
      }
      .row1 {
        padding: 60px 0;
        .input-field {
          .input-lbl {
            color: #d8d8d8;
            font-size: 18px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
          }
          .input-box {
            padding: 12px 16px;
            border-radius: 8px;
            border: 1.152px solid #e069e4;
            @include anim;
            &:focus-within {
              border-color: #d8d8d8;
            }
            .txt {
              color: #d8d8d8;
              font-size: 16px;
              font-style: normal;
              font-weight: 300;
              line-height: normal;
            }
            .Icon {
              cursor: pointer;
              svg {
                height: 20px;
                width: 20px;
              }
            }
          }
          .forgot {
            display: flex;
            justify-content: end;
            color: #d8d8d8;
            margin-right: 10px;
          }
        }
        .btn-register {
          padding: 12px 20px;
          @include anim;
          border-radius: 8px;
        }
      }
    }
  }
}
