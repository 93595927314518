.profile-page {
  height: 100%;
  min-height: 730px;
  padding: 40px 0;
  .wrap {
    .profile-box {
      min-height: 730px;
      width: 100%;
      border-radius: 18px;
      background-color: #fff;
      @include media("<=tablet") {
        padding: 20px 30px;
        min-width: 0;
      }
      .profile-header {
        padding: 30px 60px;
        display: flex;
        @include media("<=tablet") {
          padding: 10px 2px;
        }
        .heading {
          color: #1c0057;
          font-size: 30px;
          font-style: normal;
          font-weight: 800;
          @include media("<=tablet") {
            font-size: 20px;
          }
        }
      }
      .form-block {
        padding: 30px 60px;
        margin-top: 40px;
        @include media("<=tablet") {
          padding: 10px;
          margin-top: 20px;
        }
        .form-title {
          color: #1c0057;
          font-size: 15.573px;
          font-style: normal;
          font-weight: 700;
          line-height: 30px;
          border-bottom: 2px solid #1c0057;
          width: max-content;
        }
        .input-filed {
          .i-lbl {
            color: #4c535f;
            font-size: 13px;
            font-style: normal;
            font-weight: 500;
            line-height: normal;
          }
          .img-box {
            width: 110px;
            height: 110px;
            cursor: pointer;
            overflow: hidden;
            border-radius: 14px;
            border: 1px dashed #4c535f;
            background: #edf2f6;
            .img {
              height: 100%;
              width: 100%;
              border-radius: 8px;
              object-fit: cover;
            }
            .u-lbl {
              padding: 8px;
              color: #4c535f;
              text-align: center;
              font-size: 12px;
              font-style: normal;
              font-weight: 500;
              line-height: normal;
            }
            svg {
              width: 100px;
              height: 40px;
              path {
                fill: #d6d6d6;
              }
            }
            .select-file {
              display: none;
            }
          }
          .txt {
            border-radius: 6px;
            padding: 8px 10px;
            border: 2px solid #e0e4ec;
            background: linear-gradient(0deg, #edf2f6 0%, #edf2f6 100%), #fff;
            @include anim;
            &:focus {
              border-color: #1c0057;
            }
          }
        }
      }
    }
  }
}
