@import "./Colors.scss";
@import "./Props.scss";
@import "./media.scss";
@import "../components/Header/style.scss";
@import "../components/Footer/style.scss";
@import "../components/Sidebar/style.scss";
@import "../Pages/Home/style.scss";
@import "../Pages/Login/style.scss";
@import "../Pages/Registration/style.scss";
@import "../Pages/Stacking/style.scss";
@import "../Pages/History/style.scss";
@import "../Pages/Reward/style.scss";
@import "../Pages/Verification/style.scss";
@import "../Pages/Profile/style.scss";
@import "../components/NavBar/style.scss";

.font,
body {
  font-family: "Roboto", sans-serif;
}

@mixin bgImage($radius: 50%) {
  height: 100%;
  width: 100%;
  border-radius: $radius;
  background-color: #e2e2e2;
  background-repeat: no-repeat !important;
  background-size: cover !important;
  background-position: center !important;
}

@mixin dropDownAnim($duration: 0.3s) {
  animation-name: drop;
  animation-duration: $duration;
  transform-origin: top;
}

@keyframes drop {
  0% {
    transform: scaleY(0.5);
    opacity: 0;
  }
}

.wrapWidth {
  width: 100%;
  padding: 0px 4%;
  // margin: 0 auto;
  @include media("<=xlarge") {
    padding: 0px 4%;
  }
  @include media("<=large") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=desktop") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media("<=tablet") {
    width: 100%;
    padding: 0px 3%;
  }
  @include media(">=phone", "<=480px") {
    padding: 0px 5%;
  }
}

.App {
  min-height: 100vh;
}

//Drop Down
.dropDown {
  width: 100%;
  .category {
    width: 100%;
    .cbox {
      // background: #000403;
      border: 1px solid #bababa;
      padding: 0 16px;
      min-height: 42px;
      border-radius: 5px;
      box-sizing: border-box;
      min-width: 180px;
      width: 100%;
      justify-content: space-between;
      @include media(">=phone", "<=480px") {
        min-width: 150px;
      }
      .icon-arrow {
        svg {
          path {
            fill: $themeColor;
          }
        }
      }
      .slt {
        overflow: hidden;
        .ico {
          cursor: pointer;
          color: #000000d9;
          height: 22px;
          display: flex;
          align-items: center;
        }
        .lbl {
          color: #000;
          padding: 0 10px;
        }
        .unit-name {
          .unit-eng {
            color: #000000;
          }
        }
      }
      .d-img {
        margin: 2px 10px;
        .img {
          height: 25px;
          width: 25px;
          padding: 2.5px;
          border-radius: 100%;
          border: 1px solid $themeColor;
        }
      }
      .edit-icon {
        margin: 2px 10px;
        padding: 4px;
        border-radius: 100%;
        border: 1px solid $themeColor;
      }
      .dropDown-icon {
        cursor: pointer;
      }
    }
  }
  .block {
    top: 45px;
    left: 0;
    right: 0;
    border-radius: 4px;
    border: 1px solid $themeColor;
    background: #000403;
    box-shadow: 0 4px 8px rgb(0 0 0 / 10%);
    visibility: hidden;
    opacity: 0;
    z-index: 2;
    width: 100%;
    .manue {
      width: 100%;
      // height: 150px;
      overflow-y: auto;
      padding: 0px 0px;
      &::-webkit-scrollbar {
        width: 4px;
      }
      .slt {
        width: 100%;
        padding: 8px 16px;
        @include anim;
        &.active {
          background-color: $themeColor;
          color: #242424;
          .unit-name {
            .unit-eng {
              color: #242424;
            }
          }
        }
        .unit-name {
          .unit-eng {
            color: #eee;
          }
        }
        .ico {
          justify-content: flex-start;
          margin-right: 8px;
          width: 25px;
          height: 25px;
        }
        .lbl {
          flex: 1;
          margin-left: 5px;
          justify-content: flex-start;
        }
        &:hover {
          background-color: #f091f4;
          cursor: pointer;
          .ico {
            color: $themeColor;
          }
          .lbl {
            color: $themeColor;
          }
        }
      }
    }
    &.show {
      display: flex;
      visibility: visible;
      opacity: 1;
      @include dropDownAnim;
    }
  }
}

// Wrapper
.dashboard-page {
  min-height: 730px;
  background: #1c0057;
  .wrap {
  }
}
.pages-block {
  @include media("<=tablet") {
    margin-left: 0;
  }
}

.row1 {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.row2 {
  grid-gap: 30px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  @include media("<=tablet") {
    grid-template-columns: repeat(1, 1fr);
  }
}

.timer-comp {
  display: flex;
  align-items: center;
  justify-content: end;
}
.timer-box {
  display: flex;
  align-items: center;
  gap: 6px;
}
.timer-comp .time-item {
  min-width: max-content;
  display: flex;
  color: #ffffff;
  padding: 8px 6px;
  border-radius: 8px;
  align-items: center;
  justify-content: space-between;
}
.timer-comp .time-item ._vale {
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 12px;
  height: 24px;
  width: 24px;
  padding: 2px;
  border-radius: 4px;
  background: linear-gradient(162deg, #1c0057 14.01%, #e069e4 87.66%);
  @include media("<=tablet") {
    height: 18px;
    width: 18px;
  }
}
.timer-comp .time-item ._tag {
  color: #1b1b1b;
  font-size: 12px;
  margin-left: 4px;
  @include media("<=phoneH") {
    font-size: 10px;
    margin-left: 2px;
  }
}

.with-draw-modal-popup {
  width: 500px;
  min-height: 400px;
  border-radius: 14px;
  background: #1c0057;
  @include media("<=tablet") {
    width: 400px;
  }
  @include media("<=phoneH") {
    width: 100%;
  }
  .model-hdr {
    padding: 20px;
    color: #fff;
    font-size: 18.52px;
    font-style: normal;
    font-weight: 400;
    line-height: 146.023%;
    background: #e069e4;
  }
  .model-body {
    padding: 30px 40px;
    .body-title {
      padding: 14px 0;
      .b-title {
        color: #fff;
        font-size: 20px;
        font-style: normal;
        font-weight: 900;
        line-height: 25.575px;
      }
    }
    .input-field {
      .lbl {
        color: #fff;
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 146.023%;
      }
      .txt {
        padding: 8px 10px;
        border-radius: 6px;
        background: #ececec;
        border: 1px solid transparent;
        @include anim;
        &:focus {
          border-color: #e069e4;
        }
      }
    }
    .btn-width {
      color: #f5f5f5;
      font-size: 14px;
      font-style: normal;
      font-weight: 500;
      line-height: 146.023%;
      padding: 12px 18px;
      border-radius: 10px;
    }
  }
}

.loader-cmp {
  justify-content: center;
  align-items: center;
  backdrop-filter: blur(7px);
  background: rgba(255, 255, 255, 0.65);
  z-index: 9999999999;
  overflow: hidden;
  position: fixed;
  inset: 0;
  .lds-dual-ring {
    display: inline-block;
    width: 80px;
    height: 80px;
    &:after {
      content: " ";
      display: block;
      width: 64px;
      height: 64px;
      margin: 8px;
      border-radius: 50%;
      border: 6px solid $themeColor;
      border-color: $themeColor transparent $themeColor transparent;
      animation: lds-dual-ring 1.2s linear infinite;
    }
  }
}